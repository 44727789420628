<div class="p-40">
  <div class="fx-column">
    <div class="fx-row align-items-center justify-content-between">
      <div class="fx-column">
        <span class="page-title">{{'UPLOAD_DOCUMENTS.TITLE' | translate}}</span>
        <span class="page-subtitle">{{(caseObject ? 'UPLOAD_DOCUMENTS.SUBTITLE_CASE' : 'UPLOAD_DOCUMENTS.SUBTITLE') |
          translate:caseObject}}<b *ngIf="caseObject"> {{caseObject.identifier || caseObject.reference}}</b>.</span>
      </div>
      <div class="fx-row align-items-center justify-content-end ml-20">
        <button class="button" (click)="onCancel()">
          {{'ACTIONS.CANCEL' | translate}}
        </button>
        <button class="main-button ml-10" (click)="upload()" [class.disabled]="isDisabled()" [disabled]="isDisabled()">
          {{'ACTIONS.UPLOAD' | translate}}
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="mv-20"></mat-divider>
  <div class="fx-column">
    <div class="fx-row align-items-stretch justify-content-center">
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER']">
        <mat-card class="mode-card clickable h-100" roleBasedElement [class.disabled]="caseObject"
          (click)="caseObject ? null : setMode('GENERIC')" [class.selected]="mode == 'GENERIC'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_GENERIC" | translate}}</span>
            <mat-icon [class]="mode === 'GENERIC' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'GENERIC' ? 'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <span class="text">
            {{'BATCH.MODE_GENERIC_HINT' | translate}}</span>
        </mat-card>
      </div>
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER','CASE_OPERATOR']">
        <mat-card class="mode-card clickable h-100" (click)="setMode('TRADEMARKS')"
          [class.selected]="mode == 'TRADEMARKS'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_TRADEMARKS" | translate}}</span>
            <mat-icon [class]="mode === 'TRADEMARKS' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'TRADEMARKS' ?
              'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <div class="mb-8">
            <span class="text">
              {{'BATCH.MODE_TRADEMARKS_HINT' | translate}}</span>
          </div>
          <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
            [(value)]="trademarks" [disabled]="mode !== 'TRADEMARKS' || caseObject !== undefined"
            #trademarkSelect></app-trademark-select>
        </mat-card>
      </div>
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER','COUNTRY_OPERATOR']">
        <mat-card class="mode-card clickable h-100" [class.disabled]="caseObject"
          (click)="caseObject ? null : setMode('COUNTRIES')" [class.selected]="mode == 'COUNTRIES'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_COUNTRIES" | translate}}</span>
            <mat-icon [class]="mode === 'COUNTRIES' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'COUNTRIES' ?
              'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <div class="mb-8">
            <span class="text">
              {{'BATCH.MODE_COUNTRIES_HINT' | translate}}</span>
          </div>
          <app-territory-select label="{{'ENTITIES.COUNTRIES' | translate}}" [multiple]="true" [search]="true"
            initial="none" [(value)]="countries" [disabled]="mode !== 'COUNTRIES'"
            #territorySelect></app-territory-select>
        </mat-card>
      </div>
    </div>
  </div>
  <mat-divider class=" mv-20"></mat-divider>
  <div>
    <input type="file" class="file-input" (change)="onFilesSelected($event)" #fileUpload multiple
      accept="image/png, image/jpeg, image/jpg, image/tiff, application/pdf">
    <div class="file-upload fx-column justify-content-center align-items-center" (click)="fileUpload.click()"
      [class.active]="active">
      <div class="upload-icon-container">
        <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
      </div>
      <div class="mt-10 fx-column">
        <span class="text main text-center">{{"UPLOAD_DOCUMENT.INSTRUCTION" | translate}}</span>
        <span class="small main text-center">{{"UPLOAD_DOCUMENT.FILE_TYPES" | translate}}</span>
      </div>
    </div>
    <mat-divider class="mv-20" *ngIf="files.length > 0"></mat-divider>
    <div class="fx-row align-items-center justify-content-between mb-20" *ngIf="files.length > 0">
      <span class="title">
        <b>{{files.length}}</b> {{'UPLOAD_DOCUMENTS.DOCUMENTS_COUNT' | translate}}
      </span>
      <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="query"
        (change)="onQueryChange($event)"></app-input>
    </div>
    <mat-card *ngFor="let file of filtered" class="mv-10 document"
      [class.complete]="file.progress == 100 && file.status === 'DONE'" [class.error]="file.status === 'ERROR'">
      <div class="fx-row align-items-center justify-content-between">
        <div class="fx-row align-items-center justify-content-start fx-flex-30">
          <img [src]="icon(file)" [height]="35" class="mr-10" />
          <div class="fx-column">
            <span class="text bold">{{file.filename}}</span>
            <span class="text light">{{size(file)}}</span>
          </div>
        </div>
        <div class="fx-column fx-flex-20">
          <span class="text bold">{{'DOCUMENT_CLASSES.'+file.class.toUpperCase() | translate}}</span>
          <span class="text light">{{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}</span>
        </div>
        <div class="fx-column fx-flex-20">
          <span class="text bold">{{'UPLOAD_DOCUMENT.ANALYSIS_MODE_'+(file.analysis.toUpperCase())
            | translate}}</span>
          <span class="text light">{{'UPLOAD_DOCUMENT.ANALYSIS_MODE' | translate}}</span>
        </div>
        <div class="fx-column fx-flex-20">
          <span class="text bold">{{(file.confidential ? 'CONFIDENTIALITY.CONFIDENTIAL' :
            'CONFIDENTIALITY.NOT_CONFIDENTIAL') | translate}}</span>
          <span class="text light">{{'UPLOAD_DOCUMENT.CONFIDENTIALITY' | translate}}</span>
        </div>
        <div class="fx-row align-items-center justify-content-end fx-flex-10">
          <mat-icon fontSet="material-icons-outlined" class="main clickable" [matTooltipClass]="{'uzai-tooltip':true}"
            [matTooltip]="'ACTIONS.EDIT' | translate" (click)="file.edit = !file.edit">edit</mat-icon>
          <mat-icon fontSet="material-icons-outlined" class="red clickable ml-20"
            [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.DELETE' | translate"
            (click)="remove(file)">remove_circle_outline</mat-icon>
        </div>
      </div>
      <mat-divider class="mv-10" *ngIf="file.edit"></mat-divider>
      <div class="fx-row align-items-start justify-content-between" *ngIf="file.edit">
        <div class="fx-flex-30 fx-row pr-10">
          <app-input label="Name" placeholder="Name" type="text" [(value)]="file.filename" class="fx-flex"></app-input>
        </div>
        <div class="fx-flex-20 fx-row pr-10">
          <app-document-class-select label="{{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}" [multiple]="false"
            [(value)]="file.class" class="fx-flex-100" [labelOutside]="true"></app-document-class-select>
        </div>
        <div class="fx-flex-20 fx-row fx-wrap pr-10">
          <app-select label="{{'UPLOAD_DOCUMENT.ANALYSIS_MODE' | translate}}" [options]="analysisModes"
            [multiple]="false" [value]="file.analysis" (change)="onAnalysisModeChange(file,$event)"
            [labelOutside]="true" class="fx-flex-100"></app-select>
          <span class="small light mt-5">{{'UPLOAD_DOCUMENT.ANALYSIS_MODE_'+file.analysis+'_HINT' | translate}}</span>
        </div>
        <div class="fx-flex-20 fx-row pr-10">
          <app-checkbox label="{{'UPLOAD_DOCUMENT.CONFIDENTIALITY' | translate}}" [values]="confidentialities"
            [(value)]="file.confidential"></app-checkbox>
        </div>
        <div class="fx-flex-10 fx-row">
        </div>
      </div>
    </mat-card>
  </div>
</div>