import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Document } from 'src/app/models/document';
import { Paginator } from 'src/app/models/paginator';
import { AclService } from 'src/app/services/acl.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-dashboard-country-operator',
  templateUrl: './dashboard-country-operator.component.html',
  styleUrls: ['./dashboard-country-operator.component.scss']
})
export class DashboardCountryOperatorComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  lastDocuments: Paginator<Document> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };

  countries: string[] = [];

  displayedColumns: string[] = ['name', 'status', 'trademark', 'nice', 'territories', 'period', 'actions'];

  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private documentService: DocumentService,
    private router: Router,
    private toastr: ToastrService
  ) {

  }


  async ngOnInit() {
    this.loading = true;
    this.done = false;
    await this.retrieveLastDocuments(true);
    this.countries = this.aclService.countries();
    this.loading = false;
    this.done = true;
  }

  async retrieveLastDocuments(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.lastDocuments = await this.documentService.retrieveAll(this.lastDocuments.page, this.lastDocuments.limit, this.lastDocuments.offset, this.lastDocuments.sort, undefined, undefined, undefined, undefined, undefined, undefined);

      if (init && this.lastDocuments.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.lastDocuments.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  documentIcon(document: Document) {
    return this.documentService.icon(document);
  }

  toDocument(document: Document) {
    this.router.navigate(['documents', document._id], {
      state: {
        from: "DOCUMENTS"
      }
    })
  }

  formatPeriod(document: Document) {
    return this.documentService.period(document);
  }

}
