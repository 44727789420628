import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { CaseDocument, CaseStatus, CaseType } from 'src/app/models/case';
import { Document } from 'src/app/models/document';
import { NiceClass } from 'src/app/models/niceClass';
import { Paginator } from 'src/app/models/paginator';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { Trademark } from 'src/app/models/trademark';
import { Scope } from 'src/app/services/acl.service';
import { CaseCreationOrUpdate, CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

interface TimelineCriteria {
  status?: CaseStatus;
  type?: CaseType;
  propertyOffice?: string;
}

@Component({
  selector: 'app-case-declare',
  templateUrl: './case-declare.component.html',
  styleUrls: ['./case-declare.component.scss']
})
export class CaseDeclareComponent {

  trademark?: {
    ref: string;
    name: string;
    countryOfDesignation: string;
    identifierNumber: string;
    niceClassification: number[];
    registrationDate?: moment.Moment;
    applicationDate?: moment.Moment;
  };
  type?: CaseType;
  niceClassification: number[] = [];
  propertyOffice?: string;
  period?: {
    notificationDate?: moment.Moment;
    start?: moment.Moment;
    end?: moment.Moment;
    endInstructionDate?: moment.Moment;
  };
  lang?: string;

  documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: CaseDocument[] = [];

  linkedDocuments: CaseDocument[] = [];

  identifier?: string;

  loading: boolean = false;

  today: moment.Moment = moment();
  year: number = moment().year();

  caseTypes: {
    value: string,
    label: string,
    selected: boolean,
    disabled: boolean
  }[] = [
      {
        value: CaseType.PRINCIPAL,
        label: "CASE.TYPE_PRINCIPAL",
        selected: false,
        disabled: false
      },
      {
        value: CaseType.COUNTERCLAIM,
        label: "CASE.TYPE_COUNTERCLAIM",
        selected: false,
        disabled: false
      }
    ];


  langs: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "en",
        label: "LANGUAGES.en",
        selected: false
      },
      {
        value: "fr",
        label: "LANGUAGES.fr",
        selected: false
      }
    ]

  timelineCriteria: TimelineCriteria = {
    status: CaseStatus.NOTIFICATION,
    type: undefined,
    propertyOffice: undefined
  }
  private readonly timelineCriteriaSubject = new Subject<TimelineCriteria>();
  private timelineCriteriaSubscription?: Subscription;
  endInstructionDateHint: boolean = false;

  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

    this.timelineCriteriaSubscription = this.timelineCriteriaSubject
      .pipe(
        distinctUntilChanged()
      ).subscribe(async () => {
        await this.getPossiblePhases();
      });
  }

  isStepDisabled(step: number) {
    switch (step) {
      case 1:
        return false;
      case 2:
        return !this.trademark;
      case 3:
        return !this.trademark || this.niceClassification.length === 0;
      case 4:
        return !this.trademark || this.niceClassification.length === 0 || !this.type;
      case 5:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice;
      case 5:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice;
      case 6:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice || !this.period || !this.period.notificationDate;
      case 7:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice || !this.period || !this.period.notificationDate || !this.period.endInstructionDate;
      case 8:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice || !this.period || !this.period.notificationDate || !this.period.endInstructionDate || !this.period.start || !this.period.end;
      case 9:
        return !this.trademark || this.niceClassification.length === 0 || !this.type || !this.propertyOffice || !this.period || !this.period.notificationDate || !this.period.endInstructionDate || !this.period.start || !this.period.end || !this.lang;
      default:
        return true;
    }
  }

  onTrademarkChange(event: { value?: Trademark }) {
    if (event && event.value) {
      this.trademark = {
        ref: event.value._id!,
        name: event.value.name!,
        identifierNumber: event.value.identifierNumber,
        countryOfDesignation: event.value.countryOfDesignation,
        niceClassification: event.value.niceClassification?.map(niceClass => niceClass.classNumber) ?? [],
        registrationDate: event.value.registrationDate,
        applicationDate: event.value.applicationDate
      };
    } else {
      this.trademark = undefined;
    }
  }

  onNiceClassificationChange(event: any) {
    this.niceClassification = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
  }

  onCaseTypeChange(event: any) {
    if (event && event.value) {
      this.type = event.value.value;
      this.timelineCriteria.type = this.type;
      this.timelineCriteriaSubject.next({ ...this.timelineCriteria });
      // if (this.type === CaseType.COUNTERCLAIM && this.trademark && (this.trademark.applicationDate || this.trademark.registrationDate)) {
      //   const date = this.trademark.applicationDate ? moment(this.trademark.applicationDate) : (moment(this.trademark.registrationDate) || moment());
      //   const end = date.clone();
      //   const start = end.clone().add(-5, 'years');
      //   this.period = {
      //     start,
      //     end
      //   }
      // }
    }
  }

  onNotificationDateChange(date: moment.Moment) {
    if (!this.period) {
      this.period = {
        notificationDate: date
      }
    } else {
      this.period.notificationDate = date;
    }
    this.timelineCriteriaSubject.next({ ...this.timelineCriteria });
    if (this.type === CaseType.PRINCIPAL) {
      const end = date.clone();
      const start = end.clone().add(-5, 'years');
      this.period.start = start;
      this.period.end = end;
    }
  }

  onEndInstructionDateChange(date: moment.Moment) {
    if (!this.period) {
      this.period = {
        endInstructionDate: date
      }
    } else {
      this.period.endInstructionDate = date;
    }
  }

  onPeriodChange(event: any) {
    if (!this.period) {
      this.period = {
        start: event.start,
        end: event.end
      }
    } else {
      this.period.start = event.start;
      this.period.end = event.end;
    }
  }

  onPropertyOfficeChange(event: any) {
    if (event && event.value) {
      this.propertyOffice = event.value._id;
      this.timelineCriteria.propertyOffice = event.value.code;
      this.timelineCriteriaSubject.next({ ...this.timelineCriteria });
    }
  }

  onLangChange(event: any) {
    if (event && event.value) {
      this.lang = event.value.value;
    }
  }

  async declare() {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;

      if (this.trademark && this.niceClassification.length > 0 && this.type && this.propertyOffice && this.lang && this.period && this.period.start && this.period.end && this.period.notificationDate && this.period.endInstructionDate) {
        const caseToCreate: CaseCreationOrUpdate = {
          trademark: this.trademark.ref,
          type: this.type,
          niceClassification: this.niceClassification,
          propertyOffice: this.propertyOffice,
          timePeriod: {
            notificationDate: this.period.notificationDate.format("YYYY-MM-DD"),
            start: this.period.start.format("YYYY-MM-DD"),
            end: this.period.end.format("YYYY-MM-DD"),
            endInstructionDate: this.period.endInstructionDate.format("YYYY-MM-DD"),
          },
          identifier: this.identifier,
          lang: this.lang
        }
        const caseCreated = await this.caseService.create(caseToCreate);
        this.toastr.success(this.translate.instant(this.identifier ? 'CASE_DECLARE.CASE_CREATED_IDENTIFIER_MESSAGE' : 'CASE_DECLARE.CASE_CREATED_MESSAGE', { identifier: this.identifier }), this.translate.instant('CASE_DECLARE.CASE_CREATED'))
        this.router.navigateByUrl(`/cases/${caseCreated._id}`)
      }


      this.loading = false;
      this._message.emitChange("LOADING", "END");

    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`);
    }
  }

  async getPossiblePhases() {
    if (this.timelineCriteria.status && this.timelineCriteria.type && this.timelineCriteria.propertyOffice) {
      const phases = await this.caseService.getPossiblePhases(this.timelineCriteria.status, this.timelineCriteria.type, this.timelineCriteria.propertyOffice);
      const instruction = phases.find((p) => p.status === CaseStatus.INSTRUCTION);
      if (instruction && this.period && this.period.notificationDate && instruction.duration) {
        this.endInstructionDateHint = true;
        const duration = parseInt(instruction.duration.split(" ")[0]) as moment.DurationInputArg1;
        const unit = instruction.duration.split(" ")[1] as moment.unitOfTime.DurationConstructor;
        this.period.endInstructionDate = moment(this.period?.notificationDate).add(duration, unit);
      }
    }
  }

  durationWarning() {
    return this.period && this.period.end && this.period.start && moment(this.period.end).isAfter(moment(this.period.start).add(5, 'years'));
  }

}
