import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-counter-card',
  templateUrl: './counter-card.component.html',
  styleUrls: ['./counter-card.component.scss']
})
export class CounterCardComponent {
  @Input() label: string = '';
  @Input() value: number = 0;
  @Input() link?: {
    href: string,
    label: string
  };
}
