<div class="fx-column">
  <app-back></app-back>
  <div class="fx-row align-items-center justify-content-between" *ngIf="batch">
    <div class="fx-column">
      <span class="page-title">Import n°{{batch.number}}</span>
      <span class="page-subtitle">{{'BATCH.UPLOADED_ON' | translate}} {{formatDate(batch.createdAt)}}</span>
    </div>
    <button (click)="reanalyze()" class="button ml-20" aclBasedElement resource="BATCH" [scopes]="['U']">
      <div class="fx-row align-items-center justify-content-between fx-flex-100">
        <mat-icon class="dark mr-5" fontSet="material-icons-outlined">sync</mat-icon>
        <span class="text fx-flex">{{'ACTIONS.REANALYZE' | translate}}</span>
      </div>
    </button>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<div class="fx-row fx-wrap align-items-stretch justify-content-start fx-flex-100" *ngIf="batch">
  <div class="fx-flex-20 pr-8 pb-8 border-box">
    <mat-card class="p-0">
      <div class="p-8 fx-row fx-wrap align-items-start justify-content-start">
        <div class="fx-flex-100 p-8 border-box fx-column">
          <span class="small light">{{'BATCH.STATUS' | translate}}</span>
          <app-batch-progress [batch]="batch" (refresh)="refresh()"></app-batch-progress>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column">
          <span class="small light">{{'BATCH.SCORE' | translate}}</span>
          <app-batch-score [score]="score" [status]="batch.status" *ngIf="score"></app-batch-score>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column">
          <span class="small light">{{'BATCH.UPLOADER' | translate}}</span>
          <div class="fx-row justify-content-start align-items-center" *ngIf="batch.uploader && batch.uploader.ref">
            <app-avatar [user]="batch.uploader" [size]="25"></app-avatar>
            <div class="fx-column align-items-start justify-content-start ml-5">
              <span class="text">{{batch.uploader.firstName}} {{batch.uploader.lastName}}</span>
            </div>
          </div>
          <div class="fx-row justify-content-start align-items-center" *ngIf="!batch.uploader || !batch.uploader.ref">
            <img src="/assets/icons/icon.svg" class="uzai-avatar" style="width:25px;height:25px;" />
            <div class="fx-column ml-5">
              <span class="text">UZAI <span class="small light">({{'ENTITIES.INTEGRATION' | translate}})</span></span>
            </div>
          </div>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column">
          <span class="small light">{{'BATCH.UPLOADED_ON' | translate}}</span>
          <span class="text">{{formatDate(batch.createdAt)}}</span>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column">
          <span class="small light">{{'BATCH.MODE' | translate}}</span>
          <span class="text">{{'BATCH.MODE_'+batch.mode | translate}}</span>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column" *ngIf="batch.mode === 'TRADEMARKS'">
          <span class="small light">{{'ENTITIES.TRADEMARKS' | translate}}</span>
          <a (click)="toTrademark(trademark)" *ngFor="let trademark of batch.trademarks">{{trademark.name ||
            trademark.identifierNumber}}</a>
        </div>
        <div class="fx-flex-100 p-8 border-box fx-column" *ngIf="batch.mode === 'COUNTRIES'">
          <span class="small light">{{'ENTITIES.COUNTRIES' | translate}}</span>
          <app-document-countries [territories]="batch.countries" *ngIf="batch.countries"></app-document-countries>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="fx-flex-80 pr-8 pb-8 border-box fx-row fx-wrap">
    <div class="fx-flex-100 pr-8 pb-8 border-box">
      <mat-card class="p-0 fx-flex-100">
        <div class="p-16 fx-row align-items-center justify-content-between">
          <div class="fx-column">
            <span class="text bold">{{'BATCH.DOCUMENTS' | translate}}</span>
            <span class="small">{{'BATCH.DOCUMENTS_HINT' | translate}}</span>
          </div>
          <div class="fx-row align-items-center justify-content-end">
            <div class="danger-button mr-10" *ngIf="selection && selection.selected && selection.selected.length > 0"
              (click)="deleteDocuments()">
              {{ 'ACTIONS.DELETE' | translate}} {{selection.selected.length}} {{((selection.selected.length > 1 ?
              'ENTITIES.DOCUMENTS' :
              'ENTITIES.DOCUMENT') | translate).toLowerCase()}}
            </div>
            <app-select initial="none" label="{{'BATCH.STATUS' | translate}}" [options]="documentStatuses"
              [multiple]="true" (change)="onStatusChange($event)" [emitOnInit]="false"
              style="width:240px;"></app-select>
          </div>
        </div>
        <mat-divider></mat-divider>
        <app-batch-documents [documents]="batchDocuments" [(selection)]="selection"></app-batch-documents>
      </mat-card>
    </div>
  </div>
</div>