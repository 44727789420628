import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-upload-panel',
  templateUrl: './upload-panel.component.html',
  styleUrls: ['./upload-panel.component.scss']
})
export class UploadPanelComponent implements OnInit {

  expanded: boolean = true;

  constructor(
    private documentService: DocumentService,
    private router: Router
  ) {

  }


  ngOnInit(): void {

  }

  toBatch(event: any) {
    event.stopPropagation();
    this.expanded = false;
    this.router.navigateByUrl(`/documents/batches/${this.documentService.uploadDocuments[0].batch?.ref}`)
  }

  status() {
    const uploading = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.PENDING, UploadFileStatus.UPLOADING].includes(doc.status)).length;
    const errored = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.ERROR].includes(doc.status)).length;
    const done = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.DONE].includes(doc.status)).length;

    if (uploading > 0) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_IN_PROGRESS',
        loading: true,
        done: false,
        error: false
      };
    } else if (errored > 0 && uploading === 0) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_DONE_WITH_ERRORS',
        loading: false,
        done: true,
        error: true
      };
    } else if (done > 0 && uploading === 0 && done === this.documentService.uploadDocuments.length) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_DONE',
        loading: false,
        done: true,
        error: false
      };
    } else {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_UNKNOWN',
        loading: false,
        done: false,
        error: false
      };
    }
  }

  progress() {
    const total = this.documentService.uploadDocuments.length * 100;
    let progress = 0;
    this.documentService.uploadDocuments.forEach((doc: UploadFile) => {
      progress += (doc.progress ?? 0);
    })
    return Math.round(100 * progress / total);
  }

  files() {
    return this.documentService.uploadDocuments;
  }

  icon(file: File) {
    return this.documentService.icon(file);
  }

  toDocument(event: Event, file: UploadFile) {
    this.toggle(event);
    this.router.navigate(['documents', file.documentId])
  }


  toggle(event: Event) {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  close(event: Event) {
    event.stopPropagation();
    this.documentService.flushAndClosePanel();
  }
}
