import * as moment from "moment";
import { Moment } from "moment";
import { DocumentClass } from "./documentClass";
import { Trademark } from "./trademark";

export enum DocumentStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR'
}

export enum DocumentType {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  TIFF = 'image/tiff'
}

export enum AnalysisMode {
  LIGHT = "LIGHT",
  FULL = 'FULL'
}

export enum BatchDocumentStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  NO_TRADEMARK = 'NO_TRADEMARK',
  NO_TERRITORY = 'NO_TERRITORY',
  NO_DATE = 'NO_DATE',
  NO_NICE_CLASSIFICATION = 'NO_NICE_CLASSIFICATION',
  MISMATCH_NICE_CLASSIFICATION = 'MISMATCH_NICE_CLASSIFICATION',
  MISMATCH_TERRITORY = 'MISMATCH_TERRITORY'
}

export interface DocumentFilters {
  query?: string,
  classNumber: number[],
  territories: string[],
  documentClass: DocumentClass[],
  trademark: Trademark[],
  period: {
    start?: number,
    end?: number
  }
}

export interface Document {
  _id?: string;
  name: string;
  status: DocumentStatus;
  type?: DocumentType;
  class?: DocumentClass;
  pages?: number;
  size: number;
  confidential: boolean;
  analysis?: AnalysisMode;
  documentKey?: string;
  batch: {
    ref: string;
    number: number;
    status: BatchDocumentStatus;
  };
  trademarks: {
    ref: string;
    name: string;
    identifierNumber: string;
    countryOfDesignation: string;
  }[];
  niceClassification: number[];
  territories: string[];
  langs: string[];
  period?: {
    dates: Moment[];
    start: Moment;
    end: Moment;
  };
  timestamp?: Moment;
  uploader: {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  organizationId: string;
  technical: {
    cost: number;
    time: number;
    calls: string[];
    steps: string[];
    pagesNeeded: number;
  };
  createdAt: Moment;
  confidence: {
    lang: {
      [key: string]: number
    },
    class: {
      [key: string]: number
    },
    trademarks: {
      [key: string]: number
    },
    territories: {
      [key: string]: number
    },
    niceClassification: {
      [key: string]: number
    },
    period: {
      [key: string]: number
    }
  }
}
