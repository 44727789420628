import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Role, User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'src/app/services/message.service';
import { NiceService } from 'src/app/services/nice.service';
import { TerritoryService } from 'src/app/services/territory.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  loading: boolean = false;

  public sections: any = {
    top: [
      {
        label: 'NAV.SECTIONS.HOME',
        icon: 'home',
        path: '',
        active: true,
        roles: []
      },
      {
        label: 'NAV.SECTIONS.TRADEMARKS',
        icon: 'account_balance_wallet',
        path: 'trademarks',
        active: false,
        roles: ['SUPERADMIN', 'ADMIN', 'MANAGER']
      },
      {
        label: 'NAV.SECTIONS.DOCUMENTS',
        icon: 'description',
        path: 'documents',
        active: false,
        guards: [() => this.aclService.isManager()],
        roles: ['SUPERADMIN', 'ADMIN', 'MANAGER', 'COUNTRY_OPERATOR']
      },
      {
        label: 'NAV.SECTIONS.CASES',
        icon: 'folder',
        path: 'cases',
        active: false,
        guards: [() => this.aclService.isManager()],
        roles: ['SUPERADMIN', 'ADMIN', 'MANAGER', 'CASE_OPERATOR']
      },
      {
        label: 'NAV.SECTIONS.INTEGRATIONS',
        icon: 'widgets',
        path: 'integrations',
        active: false,
        guards: [() => this.aclService.isManager()],
        roles: ['SUPERADMIN', 'ADMIN', 'MANAGER']
      }
    ],
    bottom: [
      {
        label: 'NAV.SECTIONS.SUPPORT',
        icon: 'contact_support',
        path: 'support',
        active: false,
        roles: []
      },
      {
        label: 'NAV.SECTIONS.ORGANIZATION',
        icon: 'business',
        path: 'organization',
        active: false,
        roles: []
      }
    ]
  };


  uploadPanel: boolean = false;

  user?: User;

  subscription: Subscription;

  positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'start', overlayY: 'center' }
    ),
  ];

  constructor(
    private _cd: ChangeDetectorRef,
    private _message: MessageService,
    private aclService: AclService,
    private authService: AuthenticationService,
    private niceService: NiceService,
    private territoryService: TerritoryService,
    private router: Router
  ) {
    // this.territoryService.retrieve();
    // this.niceService.retrieve();
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "NAV.SECTION") {
        this._active(change.data.path)
      }
      if (change && change.key == "LOADING.START") {
        this.loading = true;
        this._cd.detectChanges();
      }
      if (change && change.key == "LOADING.END") {
        this.loading = false;
        this._cd.detectChanges();
      }
      if (change && change.key == "UPLOAD.SHOW") {
        this.showUploadPanel();
      }
      if (change && change.key == "UPLOAD.HIDE") {
        this.hideUploadPanel();
      }
    });
  }

  _active(path: string) {
    this.sections.top.forEach((section: any) => {
      if (section.path === path) {
        section.active = true;
      } else {
        section.active = false;
      }
    });
    this.sections.bottom.forEach((section: any) => {
      if (section.path === path) {
        section.active = true;
      } else {
        section.active = false;
      }
    });
    this._cd.detectChanges();
  }

  showUploadPanel() {
    this.uploadPanel = true;
  }

  hideUploadPanel() {
    this.uploadPanel = false;
  }

  logout() {
    this.authService.logout();
  }

  toSettings() {
    this.router.navigate(['settingss'])
  }
}
