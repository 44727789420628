import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TrademarksImportOverviewListDialogComponent } from 'src/app/dialogs/trademarks-import-overview-list-dialog/trademarks-import-overview-list-dialog.component';
import { TrademarkSearch } from 'src/app/models/trademark';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { TerritoryService } from 'src/app/services/territory.service';
import { TrademarkService } from 'src/app/services/trademark.service';
import { UtilsService } from 'src/app/services/utils.service';
import * as XLSX from "xlsx";

export interface TrademarkErrors {
  missingIdentifier: { line: number, identifierNumber?: string, country?: string }[],
  missingCountry: { line: number, identifierNumber?: string, country?: string }[],
  invalidCountry: { line: number, identifierNumber?: string, country?: string }[],
  unprocessable: { line: number, identifierNumber?: string, country?: string }[]
}
@Component({
  selector: 'app-trademark-declare-import',
  templateUrl: './trademark-declare-import.component.html',
  styleUrls: ['./trademark-declare-import.component.scss']
})
export class TrademarkDeclareImportComponent implements OnInit {

  public active: boolean = false;
  public loading: boolean = false;
  public done: boolean = false;
  private accepted: string[] = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];

  file?: File;

  wipoCodes: string[] = ["wo"];

  import: {
    isExcel: boolean;
    prepared: boolean;
    parsed: boolean;
    delimiter: string;
    sheets: { value: string, label: string, selected: boolean }[];
    sheet?: string;
    firstLine?: number;
    countryColumn: string;
    identifierColumn: string;
    trademarks: { line: number, identifierNumber: string, country: string }[],
    errors: TrademarkErrors
  } = {
      isExcel: false,
      prepared: false,
      parsed: false,
      delimiter: ";",
      sheets: [],
      sheet: undefined,
      firstLine: 1,
      identifierColumn: 'A',
      countryColumn: 'B',
      trademarks: [],
      errors: {
        missingCountry: [],
        missingIdentifier: [],
        invalidCountry: [],
        unprocessable: []
      }
    };

  displayedColumns: string[] = ['select', 'name', 'identifier', 'countryOfDesignation', 'niceClassification', 'status', 'representation'];
  public trademarks: TrademarkSearch[] = [];
  dataSource: MatTableDataSource<TrademarkSearch> = new MatTableDataSource<TrademarkSearch>([])
  public selection = new SelectionModel<TrademarkSearch>(true, []);


  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private territoryService: TerritoryService,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.retrieveCountries();
  }

  async retrieveCountries() {
    const territories = await this.territoryService.retrieve();
    territories.forEach((territory) => {
      this.wipoCodes.push(territory.id.toLowerCase());
      if (territory.alternateCodes) {
        this.wipoCodes.push(...territory.alternateCodes.map(code => code.toLowerCase()))
      }
    })
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
    const file = event.dataTransfer.files[0];
    this.handleFile(file);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.handleFile(file);
  }

  handleFile(file: File) {
    this.file = file;
    if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)) {
      this.import.isExcel = true;
      this._prepareFile(file)
    } else if (file.type === "text/csv") {
      this.import.isExcel = false;
      this._prepareFile(file);
    } else {
      this.toastr.warning("TRADEMARK_DECLARE.UNAUTHORIZED_FORMAT_MESSAGE");
      this.removeFile();
    }
  }

  removeFile() {
    this.file = undefined;
    this.import = {
      isExcel: false,
      prepared: false,
      parsed: false,
      delimiter: ";",
      sheets: [],
      firstLine: 1,
      identifierColumn: 'A',
      countryColumn: 'B',
      trademarks: [],
      errors: {
        missingCountry: [],
        missingIdentifier: [],
        invalidCountry: [],
        unprocessable: []
      }
    }
  }

  _prepareFile(file: File) {
    this.import.prepared = false;
    this.import.trademarks = [];
    this.import.errors.missingCountry = [];
    this.import.errors.missingIdentifier = [];
    this.import.errors.unprocessable = [];
    var reader = new FileReader();
    let arrayBuffer;
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      arrayBuffer = reader.result;
      var data = new Uint8Array(arrayBuffer as ArrayBufferLike);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, this.import.isExcel ? { type: "binary" } : { type: "binary", FS: this.import.delimiter });
      if (!this.import.sheet) {
        this.import.sheets = workbook.SheetNames.map((sheet, index) => ({ selected: index === 0, label: sheet, value: sheet }));
      }
    }
    this.import.prepared = true;
  }

  parseFileDisabled() {
    return !this.import.countryColumn || !this.import.identifierColumn || this.import.firstLine === undefined
      || (this.import.isExcel && !this.import.sheet) || (!this.import.isExcel && !this.import.delimiter);
  }

  _parseFile(file: File) {
    this.import.parsed = false;
    this.import.trademarks = [];
    this.import.errors.missingCountry = [];
    this.import.errors.missingIdentifier = [];
    this.import.errors.unprocessable = [];
    var reader = new FileReader();
    let arrayBuffer;
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      arrayBuffer = reader.result;
      var data = new Uint8Array(arrayBuffer as ArrayBufferLike);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, this.import.isExcel ? { type: "binary" } : { type: "binary", FS: this.import.delimiter });
      if (!this.import.sheet) {
        this.import.sheets = workbook.SheetNames.map((sheet, index) => ({ selected: index === 0, label: sheet, value: sheet }));
      }
      var sheet = this.import.sheets.find(sheet => sheet.selected)?.value || workbook.SheetNames[0];
      var worksheet = workbook.Sheets[sheet];
      const rows = XLSX.utils.sheet_to_json(worksheet, { raw: true, header: "A", range: Number(this.import.firstLine) - 1 });
      rows.forEach((row: any, index: number) => {
        const line = index + Number(this.import.firstLine);
        let valid = true;
        let lineObj = {
          line,
          identifierNumber: row[this.import.identifierColumn],
          country: row[this.import.countryColumn]
        };
        if (row[this.import.identifierColumn] && !row[this.import.countryColumn]) {
          this.import.errors.missingCountry.push(lineObj);
          valid = false;
        }
        if (!row[this.import.identifierColumn] && row[this.import.countryColumn]) {
          this.import.errors.missingIdentifier.push(lineObj);
          valid = false;
        }
        if (row[this.import.identifierColumn] && row[this.import.countryColumn] && !this._validCountry(row[this.import.countryColumn])) {
          this.import.errors.invalidCountry.push(lineObj);
          valid = false;
        }
        if (valid) {
          this.import.trademarks.push(lineObj)
        }
      });
      this.search();
    }
    console.log("after parsing")
    this.import.parsed = true;
  }

  _validCountry(country: string) {
    return this.wipoCodes.includes(country.toLowerCase());
  }

  overview(type: string, text: string) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      maxHeight: '600px',
      autoFocus: false,
      data: {
        title: 'TRADEMARK_DECLARE.IMPORT_PRE_OVERVIEW',
        text: text,
        trademarks: type === 'trademarks' ? this.import.trademarks : this.import.errors[type as keyof TrademarkErrors]
      }
    }
    const dialog = this.dialog.open(TrademarksImportOverviewListDialogComponent, config);
  }

  hasErrors() {
    return this.import.errors.invalidCountry.length > 0 || this.import.errors.missingCountry.length > 0 || this.import.errors.missingIdentifier.length > 0 || this.import.errors.unprocessable.length > 0;
  }

  isImportDisabled() {
    return !this.import.prepared || !this.import.parsed || this.import.trademarks.length === 0 || this.loading;
  }

  async importFile() {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      const trademarks = this.import.trademarks.map(trademark => ({ identifierNumber: trademark.identifierNumber, countryOfDesignation: [trademark.country] }));
      const result = await this.trademarkService.search(trademarks);
      this.toastr.success(this.translate.instant(result.length > 1 ? "TRADEMARK_DECLARE.IMPORTS_SUCCESSFULL" : "TRADEMARK_DECLARE.IMPORT_SUCCESSFULL", { count: result.length }))
      this.loading = false;
      this.router.navigate([".."], { relativeTo: this.route })
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC')
    }
  }

  icon(file: File) {
    return this.documentService.icon(file);
  }

  size(file: File) {
    return this.documentService.size(file);
  }

  isSelected(row: TrademarkSearch) {
    return this.selection.selected.map(doc => `${doc.tmOffice}_${doc.ST13}`).includes(`${row.tmOffice}_${row.ST13}`)
  }

  isDisabled(row: TrademarkSearch) {
    return row.tmOffice === 'WO';
  }

  isAllSelected() {
    return this.dataSource.data.filter(row => !this.isDisabled(row)).every(row => this.isSelected(row));
  }

  isSomeSelected() {
    return !this.dataSource.data.filter(row => !this.isDisabled(row)).every(row => this.isSelected(row)) && this.dataSource.data.filter(row => !this.isDisabled(row)).some(row => this.isSelected(row));
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      const difference = _.differenceBy(this.selection.selected, this.dataSource.data.filter(row => !this.isDisabled(row)), x => `${x.tmOffice}_${x.ST13}`)
      this.selection.setSelection(...difference)
      return;
    }
    this.selection.setSelection(...[...this.selection.selected, ...this.dataSource.data.filter(row => !this.isDisabled(row)).filter(row => !this.isSelected(row))])
  }

  toggle(row: TrademarkSearch) {
    if (this.isSelected(row)) {
      this.selection.setSelection(...this.selection.selected.filter(doc => `${doc.tmOffice}_${doc.ST13}` !== `${row.tmOffice}_${row.ST13}`))
    } else {
      this.selection.setSelection(...[...this.selection.selected, row])
    }
  }

  onSortChange(event: any) {

    if (event.active === 'tmName') {
      this.trademarks.sort((a, b) => event.direction === 'desc' ? (b.tmName || '').localeCompare(a.tmName || '') : (a.tmName || '').localeCompare(b.tmName || ''));
    } else if (event.active === 'applicationNumber') {
      this.trademarks = this.trademarks.sort((a, b) => event.direction === 'desc' ? b.applicationNumber.localeCompare(a.applicationNumber) : a.applicationNumber.localeCompare(b.applicationNumber));
    } else if (event.active === 'tmOffice') {
      this.trademarks = this.trademarks.sort((a, b) => event.direction === 'desc' ? this.translate.instant(`TERRITORIES.${b.tmOffice.toUpperCase()}`).localeCompare(this.translate.instant(`TERRITORIES.${a.tmOffice.toUpperCase()}`)) : this.translate.instant(`TERRITORIES.${a.tmOffice.toUpperCase()}`).localeCompare(this.translate.instant(`TERRITORIES.${b.tmOffice.toUpperCase()}`)));
    }
    this.dataSource = new MatTableDataSource<TrademarkSearch>(this.trademarks);
  }

  async search() {
    if (this.import.trademarks && this.import.trademarks.length > 0) {
      this.loading = true;
      this.done = false;
      try {
        this.trademarks = await this.trademarkService.search(this.import.trademarks.map(t => ({ identifierNumber: t.identifierNumber, countryOfDesignation: [t.country] })));
        this.dataSource = new MatTableDataSource<TrademarkSearch>(this.trademarks);
        this.toggleAllRows();
        this.loading = false;
        this.done = true;
      } catch (err: any) {
        if (err && err.status === 404) {
          this.loading = false;
          this.done = true;
        } else {
          this.loading = false;
          this.done = false;
          this.toastr.error(`ERRORS.GENERIC`);
        }
      }
    } else {
      this.loading = false;
      this.done = true;
    }

  }
}
