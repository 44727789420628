<div class="fx-column">
  <app-back></app-back>
  <div class="fx-row align-items-center justify-content-between">
    <div class="fx-row align-items-center justify-content-start">
      <div class="fx-column" *ngIf="trademark && trademark.name">
        <span class="page-title">{{trademark.name}}</span>
        <span class="page-subtitle">{{trademark.countryOfDesignation}} -
          {{trademark.identifierNumber}}</span>
      </div>
      <div class="fx-column" *ngIf="trademark && !trademark.name">
        <span class="page-title">{{trademark.identifierNumber}}</span>
        <span class="page-subtitle">{{trademark.countryOfDesignation}}</span>
      </div>
      <app-trademark-status [status]="trademark.status" *ngIf="trademark" class="ml-10"></app-trademark-status>
    </div>
    <div class="fx-row align-items-center justify-content-between">
      <button [cdkMenuTriggerFor]="menu" class="button ml-10" *ngIf="mode === 'CONSULTATION'">
        <div class="fx-row align-items-center justify-content-between fx-flex-100">
          <span class="dark fx-flex">{{'ACTIONS.ACTIONS' | translate}}</span>
          <mat-icon class="dark ml-5" fontSet="material-icons-outlined">arrow_drop_down</mat-icon>
        </div>
      </button>
      <ng-template #menu>
        <mat-card class="menu-container p-0 elevation" cdkMenu>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="mode = 'EDITION'" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']"
              *ngIf="!trademark.ST13">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">edit</mat-icon>
              <span class="text">{{'ACTIONS.EDIT' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="refresh()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['U']"
              *ngIf="trademark.ST13">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">sync</mat-icon>
              <span class="text">{{'ACTIONS.SYNCHRONIZE' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="delete()" cdkMenuItem aclBasedElement resource="TRADEMARK" [scopes]="['D']">
              <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
              <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
            </div>
          </div>
        </mat-card>
      </ng-template>
      <button class="button ml-20 fx-flex" *ngIf="mode === 'EDITION' || mode == 'COMPARISON'"
        (click)="mode = 'CONSULTATION'">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" *ngIf="mode === 'EDITION' || mode == 'COMPARISON'" (click)="update()"
        [disabled]="loading" [class.disabled]="loading">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<app-trademark-content [trademark]="trademark" [refreshed]="refreshed" [mode]="mode"
  (reproductionChange)="onReproductionChange($event)"></app-trademark-content>
<app-trademark-coverage [trademark]="trademark" class="fx-flex-100"
  *ngIf="trademark && trademark.identifierNumber && mode === 'CONSULTATION'"></app-trademark-coverage>