<div *ngIf="document">
  <div class="fx-row align-items-center justify-content-between p-16">
    <div class="fx-column fx-flex">
      <span class="title bold">
        {{'DOCUMENT.EDIT_METADATA' | translate}}
      </span>
      <span class="small light">{{document.name}}</span>
    </div>
    <mat-icon class="ml-20 dark clickable" fontSet="material-icons-outlined" (click)="onClose()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="p-16">
    <mat-card class="annotation-instructions">
      <div class="fx-column">
        <span class="text main bold">{{'DOCUMENT.ADD_PROOFS' | translate}}</span>
        <span class="text dark">{{'DOCUMENT.ANNOTATE_DOCUMENT_HINT' | translate}}</span>
        <div class="fx-row align-items-center justify-content-start mt-10">
          <mat-slide-toggle color="main" (change)="onToggleDraw()" [checked]="isDraw">
            {{"DOCUMENT.ANNOTATE_MODE"|translate}}
          </mat-slide-toggle>
        </div>
        <mat-divider class="mv-10" *ngIf="isDraw && drawing"></mat-divider>
        <div class="fx-column" *ngIf="isDraw && drawing">
          <span class="small light">{{'DOCUMENT.CHOOSE_EVIDENCE_TYPE' | translate}}</span>
          <div class="fx-column align-items-start justify-content-start mt-10">
            <div class="fx-row align-items-center justify-content-start">
              <app-select label="{{'DOCUMENT.EVIDENCE_TYPE' | translate}}" [options]="evidenceTypesOptions"
                [multiple]="false" [(value)]="evidenceType.type" [emitOnInit]="false" style="width:180px;"></app-select>
              <ng-container [ngSwitch]="evidenceType.type">
                <app-trademark-select *ngSwitchCase="'TRADEMARK'" label="{{'DOCUMENT.TRADEMARK_EVIDENCE' | translate}}"
                  [multiple]="false" [search]="true" class="ml-10" [(value)]="evidenceType.metadata"
                  [emitOnInit]="false" style="width:180px;"></app-trademark-select>
                <app-nice-select *ngSwitchCase="'NICE_CLASS'" label="{{'DOCUMENT.NICE_CLASS_EVIDENCE' | translate}}"
                  [multiple]="false" [search]="true" class="ml-10" [(value)]="evidenceType.metadata"
                  [emitOnInit]="false" style="width:180px;"></app-nice-select>
                <app-territory-select *ngSwitchCase="'LOCATION'" label="{{'DOCUMENT.LOCATION_EVIDENCE' | translate}}"
                  [multiple]="false" [search]="true" class="ml-10" [(value)]="evidenceType.metadata"
                  [emitOnInit]="false" style="width:180px;"></app-territory-select>
                <app-datepicker *ngSwitchCase="'DATE'" placeholder="{{'DOCUMENT.DATE_EVIDENCE' | translate}}"
                  [(value)]="evidenceType.metadata" style="width:180px;" class="ml-10"></app-datepicker>
                <app-daterangepicker *ngSwitchCase="'PERIOD'" placeholder="{{'DOCUMENT.PERIOD_EVIDENCE' | translate}}"
                  [value]="{start:evidenceType.period?.start,end:evidenceType.period?.end}"
                  (change)="onEvidenceTypePeriodChange($event)" style="width:180px;"
                  class="ml-10"></app-daterangepicker>
              </ng-container>
            </div>
            <div class="fx-row align-items-center justify-content-start mt-10">
              <button class="button" (click)="onCancelAnnotation()" [matTooltipClass]="{'uzai-tooltip':true}"
                [matTooltip]="'DOCUMENT.CANCEL_ANNOTATION' | translate">
                {{'ACTIONS.CANCEL' | translate}}
              </button>
              <button class="main-button ml-10" (click)="isAnnotationDisabled() ? null : onConfirmAnnotation()"
                [class.disabled]="isAnnotationDisabled()" [matTooltipClass]="{'uzai-tooltip':true}"
                [matTooltip]="'DOCUMENT.CONFIRM_ANNOTATION' | translate" [matTooltipDisabled]="isAnnotationDisabled()">
                {{'ACTIONS.VALIDATE' | translate}}
              </button>
              <!-- <mat-icon class="annotation-icon" fontSet="material-icons-outlined" (click)="onCancelAnnotation()"
                 [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'DOCUMENT.CANCEL_ANNOTATION' | translate">close</mat-icon>
              <mat-icon class="annotation-icon" fontSet="material-icons-outlined"
                (click)="isAnnotationDisabled() ? null : onConfirmAnnotation()"
                [class.disabled]="isAnnotationDisabled()"  [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'DOCUMENT.CONFIRM_ANNOTATION' | translate"
                [matTooltipDisabled]="isAnnotationDisabled()">check</mat-icon> -->
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div *ngFor="let metadatum of metadataEvidences">
    <div class="p-16 fx-column">
      <div class="fx-row align-items-center justify-content-between">
        <div class="fx-column">
          <span class="text bold">{{metadatum.title | translate}}</span>
          <span class="text light" *ngIf="metadatum.entries.length === 0">{{metadatum.placeholder | translate}}</span>
        </div>
        <div class="fx-row justify-content-end">
          <app-metadata-missing [document]="document" *ngIf="metadatum.entries.length === 0"></app-metadata-missing>
          <mat-icon class="green clickable ml-10" fontSet="material-icons-outlined" (click)="addDocumentLang()"
            [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'DOCUMENT.ADD_DOCUMENT_LANG' | translate"
            *ngIf="metadatum.type === 'LANG'">add_circle_outline</mat-icon>
        </div>
      </div>
      <span class="text light" *ngIf="metadatum.entries.length > 0">{{metadatum.hint | translate}}</span>
      <div class="mt-5 fx-row fx-wrap align-items-center justify-content-start">
        <div class="fx-row align-items-center justify-content-start metadata border-box fx-flex-100"
          *ngFor="let entry of metadatum.entries.slice(0,limits[metadatum.type])"
          (mouseenter)="onMouseEnter(entry.type,entry.value)" (mouseleave)="onMouseLeave(entry.type,entry.value)">
          <div class="fx-flex-50 fx-wrap align-items-center justify-content-start">
            <ng-container [ngSwitch]="metadatum.type">
              <span class="text" *ngSwitchCase="metadataType.LANG">{{'LANGUAGES.'+entry.value.toLowerCase() |
                translate}}</span>
              <span class="text" *ngSwitchCase="metadataType.CLASS">{{'DOCUMENT_CLASSES.'+entry.value.toUpperCase() |
                translate}}</span>
              <div class="fx-column" *ngSwitchCase="metadataType.TRADEMARK">
                <!-- Trademark with name -->
                <div class="fx-column"
                  *ngIf="entry.value && entry.value.split('_') && entry.value.split('_')[1].length > 0">
                  <span class="text main fx-flex-100">{{entry.value.split('_')[1]}}</span>
                  <span class="small light fx-flex-100">{{entry.value.split('_')[3]}} -
                    {{entry.value.split('_')[2]}}</span>
                </div>
                <!-- Trademark with no name -->
                <div class="fx-column"
                  *ngIf="entry.value && entry.value.split('_') && entry.value.split('_')[1].length == 0">
                  <span class="text main fx-flex-100">{{entry.value.split('_')[2]}}</span>
                  <span class="small light fx-flex-100">{{entry.value.split('_')[3]}}</span>
                </div>
              </div>
              <div class="fx-row align-items-center justify-content-start" *ngSwitchCase="metadataType.NICE_CLASS">
                <div class="chip main" [matTooltipClass]="{'uzai-tooltip':true}"
                  [matTooltip]="'NICE_CLASSES.'+entry.value | translate">
                  <span><b>{{'NICE_CLASSES.CLASS_NUMBER' |
                      translate}}{{entry.value}}</b></span>
                </div>
              </div>
              <div class="fx-row fx-flex-100 align-items-center justify-content-start"
                *ngSwitchCase="metadataType.LOCATION">
                <img [src]="'/assets/countries/'+entry.value.toLowerCase()+'.svg'" class="country-flag" />
                <span class="text ml-10">{{('TERRITORIES.'+entry.value.toUpperCase() | translate)}}</span>
              </div>
              <span class="text" *ngSwitchCase="metadataType.DATE">{{formatDateOrPeriod(entry)}}</span>
            </ng-container>
          </div>
          <div class="fx-row align-items-center justify-content-start fx-flex-20">
            <span class="small light">{{(entry.manual ?
              'DOCUMENT.MANUAL_PROOF' :
              'DOCUMENT.AUTOMATIC_PROOF') | translate}}</span>
            <mat-icon class="light clickable ml-5" style="transform:scale(0.75)" *ngIf="entry.manual && entry.user"
              [cdkMenuTriggerFor]="menu">help_outline</mat-icon>
            <ng-template #menu>
              <mat-card class="menu-container p-8 elevation" cdkMenu>
                <div class="fx-row justify-content-start align-items-center">
                  <app-avatar [user]="entry.user" [size]="24"></app-avatar>
                  <div class="fx-column align-items-start justify-content-start ml-10">
                    <span class="small">{{entry.user.firstName}} {{entry.user.lastName}}</span>
                    <span class="small light">{{formatDatetime(entry.updatedAt)}}</span>
                  </div>
                </div>
              </mat-card>
            </ng-template>
            <mat-icon class="light clickable ml-5" style="transform:scale(0.75)"
              *ngIf="!entry.manual && entry.type === metadataType.DATE && entry.timestamping"
              [matTooltip]="'DOCUMENT.TIMESTAMP_PROOF' | translate"
              [matTooltipClass]="{'uzai-tooltip':true}">help_outline</mat-icon>
          </div>
          <div class="fx-row align-items-center justify-content-start fx-flex-20">
            <app-metadata-confidence [value]="(entry.confidence || 0) * 100"></app-metadata-confidence>
          </div>
          <div class="fx-row align-items-center justify-content-end fx-flex-10">
            <mat-icon class="main clickable" fontSet="material-icons-outlined" (click)="editDocumentClass()"
              [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'DOCUMENT.EDIT_DOCUMENT_CLASS' | translate"
              *ngIf="metadatum.type === 'CLASS'">edit</mat-icon>
            <mat-icon class="main clickable mr-5" fontSet="material-icons-outlined"
              (click)="editDocumentLang(entry.value)" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'DOCUMENT.EDIT_DOCUMENT_LANG' | translate" *ngIf="metadatum.type === 'LANG'">edit</mat-icon>
            <mat-icon class="red clickable" fontSet="material-icons-outlined"
              (click)="removeMetadata(metadatum.type,entry)" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'DOCUMENT.DELETE_METADATA_TITLE' | translate"
              *ngIf="metadatum.type !== 'CLASS'">remove_circle_outline</mat-icon>
          </div>
        </div>
        <div class="fx-row w-100 align-items-center justify-content-end mt-10 clickable"
          *ngIf="metadatum.multiple && metadatum.entries.length > 3">
          <span class="text main"
            (click)="limits[metadatum.type] === 3 ? limits[metadatum.type] = metadatum.entries.length : limits[metadatum.type] = 3">{{(limits[metadatum.type]
            === 3 ? 'UTILS.SEE_MORE' : 'UTILS.SEE_LESS') |
            translate}}</span>
          <mat-icon class="main ml-5" fontSet="material-icons-outlined">{{limits[metadatum.type] === 3 ?
            'keyboard_arrow_down'
            :
            'keyboard_arrow_up'}}</mat-icon>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>