<div class="mb-8 fx-row align-items-center justify-content-between">
  <span class="text light mb-8 fx-flex-100">{{'DOCUMENT.PERIOD' | translate}}</span>
  <mat-icon [class.shown]="shown" class="visibility-icon" fontSet="material-icons-outlined">{{shown ? 'visibility' :
    'visibility_off'}}</mat-icon>
</div>
<div class="fx-row fx-flex-100 fx-wrap align-items-start justify-content-start">
  <div class="fx-row fx-flex-100 align-items-center justify-content-start mb-5">
    <span class="smaller bold light fx-flex-15"></span>
    <span class="smaller bold light fx-flex text-center" *ngFor="let month of months; let index = index">
      {{(index % 2 === 0) ? month : ''}}
    </span>
  </div>
  <div class="fx-row fx-flex-100 align-items-center justify-content-start" *ngFor="let year of years">
    <div class="fx-flex-15 fx-row align-items-center justify-content-end">
      <span class="smaller bold light mr-10">{{year}}</span>
    </div>
    <div class="month fx-flex" [class.active]="heatmap[year][index]" [matTooltipClass]="{'uzai-tooltip':true}"
      [matTooltip]="month+' '+year" *ngFor="let month of months; let index = index">
    </div>
  </div>
</div>