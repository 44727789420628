import { Moment } from "moment";
import { DocumentClass } from "./documentClass";

export enum BatchStatus {
  OPENED = 'OPENED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR'
}

export enum BatchMode {
  GENERIC = 'GENERIC',
  TRADEMARKS = 'TRADEMARKS',
  COUNTRIES = 'COUNTRIES'
}

export enum BatchDocumentStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  NO_TRADEMARK = 'NO_TRADEMARK',
  NO_TERRITORY = 'NO_TERRITORY',
  NO_DATE = 'NO_DATE',
  NO_NICE_CLASSIFICATION = 'NO_NICE_CLASSIFICATION',
  MISMATCH_NICE_CLASSIFICATION = 'MISMATCH_NICE_CLASSIFICATION',
  MISMATCH_TERRITORY = 'MISMATCH_TERRITORY'
}

export interface Batch {
  _id?: string;
  number: number;
  status: BatchStatus;
  mode: BatchMode;
  documents: number;
  report: {
    ok: number;
    warning: number;
    error: number;
  }
  trademarks?: [
    {
      ref: string;
      name: string;
      identifierNumber: string;
      countryOfDesignation: string;
    }
  ],
  countries?: string[],
  uploader: {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  technical: {
    cost: number;
    time: number;
  };
  organizationId: string;
  createdAt: Moment;
}

export interface BatchPopulatedDocument {
  _id: string;
  name: string;
  type: string;
  class: DocumentClass;
  status: BatchDocumentStatus[];
}