<mat-card class="mt-20">
  <div class="fx-row fx-flex-100 align-items-end justify-content-between">
    <div class="fx-row align-items-start justify-content-start">
      <app-input label="{{'TRADEMARK.IDENTIFIER_NUMBER' | translate}}"
        placeholder="{{'TRADEMARK.IDENTIFIER_NUMBER' | translate}}" type="text" [(value)]="query.identifierNumber"
        hint="{{'TRADEMARK.IDENTIFIER_NUMBER_HINT' | translate}}" style="width:210px;"></app-input>
      <app-territory-select label="{{'TRADEMARK.DESIGNATION_COUNTRY_SHORT' | translate}}" [multiple]="false"
        [search]="true" class="ml-10" initial="none" [labelOutside]="true" [(value)]="query.countryOfDesignation"
        (change)="onCountryOfDesignationChange($event)" style="width:210px;"></app-territory-select>
    </div>
    <button class="button ml-10 mb-20" (click)="search()" [disabled]="isSearchDisabled()"
      [class.disabled]="isSearchDisabled()" aclBasedElement resource="TRADEMARK" [scopes]="['C']">
      {{'ACTIONS.SEARCH' | translate}}
    </button>
  </div>
  <!-- <button class="main-button mb-20" (click)="declare()" [disabled]="!canDeclare()" [class.disabled]="!canDeclare()"
      aclBasedElement resource="TRADEMARK" [scopes]="['C']">
      {{'ACTIONS.DECLARE' | translate}}
    </button> -->
  <app-progress-bar mode="indeterminate" [loading]="loading" class="mt-16"></app-progress-bar>
</mat-card>
<mat-card class="p-0 mt-20 mb-16" *ngIf="done && !loading">
  <div class="p-16 fx-column">
    <span class="text bold">{{'TRADEMARK_DECLARE.SEARCH_RESULTS' | translate}}</span>
    <span class="text">{{'TRADEMARK_DECLARE.SEARCH_RESULTS_HINT' | translate}}</span>
  </div>
  <mat-divider></mat-divider>
  <table mat-table [dataSource]="dataSource" *ngIf="trademarks.length > 0 " style="z-index: 0;" matSort
    (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary" [checked]="isAllSelected()"
          [indeterminate]="isSomeSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(row) : null"
          [checked]="isSelected(row)" *ngIf="!isDisabled(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-column">
          <span class="main bold">{{trademark.tmName}}</span>
          <span class="small light" *ngIf="trademark.isDesignation">{{'TRADEMARK.DESIGNATED_COUNTRY'
            | translate}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.APPLICATION_NUMBER_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-column">
          <span class="text">{{trademark.applicationNumber}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="countryOfDesignation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class="small light bold">
          {{'ENTITIES.TERRITORY' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <app-trademark-country [wipoCode]="trademark.tmOffice"></app-trademark-country>
      </td>
    </ng-container>
    <ng-container matColumnDef="niceClassification">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.NICE_CLASSIFICATION_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-row fx-wrap align-items-center justify-content-start">
          <app-trademark-nice-classification
            [niceClassification]="trademark.niceClass"></app-trademark-nice-classification>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span class="small light bold">
          {{'TRADEMARK.STATUS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-row align-items-center justify-content-start">
          <app-trademark-status [status]="trademark.tradeMarkStatus"
            *ngIf="trademark.tradeMarkStatus"></app-trademark-status>
          <span class="text light" *ngIf="!trademark.tradeMarkStatus">-</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="representation">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.REPRODUCTION_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <img [src]="trademark.markImageURI" class="trademark-representation" *ngIf="trademark.markImageURI" />
        <span class="text light" *ngIf="!trademark.markImageURI">-</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let trademark; columns: displayedColumns;">
    </tr>
  </table>
  <div class="p-16" *ngIf="trademarks.length === 0">
    <span class="text light">{{'TRADEMARK_DECLARE.SEARCH_NO_RESULTS' | translate}}</span>
  </div>
</mat-card>