import { CdkMenu, CdkMenuBase, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription, concat, of } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';

type Option = {
  label: string;
  value: any;
  selected?: boolean
}
type SelectedOption = Option & { selected: boolean, display?: boolean }

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() options: Option[] = [];
  @Input() class?: string;
  @Input() emitOnInit: boolean = true;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;

  selected: SelectedOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: string | string[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit() {
    this.setInitialState();
  }

  _isSelected(option: string) {
    return this.selected.map((t) => t.value).includes(option);
  }

  populate() {
    if (this.multiple && this.value && (this.value as string[]).length > 0) {
      const values = (this.value as string[]);
      const matching = this.options.filter((t) => values.includes(t.value))
      this.selected = matching.map((t) => ({ value: t.value, label: t.label, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as string);
      const matching = this.options.find((t) => t.value === value);
      if (matching)
        this.selected = [{ value: matching.value, label: matching.label, selected: true, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  setInitialState() {
    if (this.multiple && this.value && this.value?.length > 0) {
      const values = (this.value as string[]);
      const matching = this.options.filter((t) => values.includes(t.value))
      this.selected = matching.map((t) => ({ value: t.value, label: t.label, selected: true }));

      this.options.forEach(option => {
        if (values.includes(option.value)) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
      const value = _.unionWith([...this.options.filter(t => t.selected).map((t) => t.value), ...this.selected.map((o) => o.value)]);
      if (value.length > 0) {
        this.selection = this.formatSelection();
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as string);
      const matching = this.options.find((t) => t.value === value);
      if (matching) {
        this.selected = [{ value: matching.value, label: matching.label, selected: true }];
        const option = this.options.find(t => t.value == value);
        if (option) {
          option.selected = true;
          this.selection = this.translate.instant(option.label);
        }
      }

    }
    if (this.emitOnInit) {
      this.onChange();
    }
  }

  removeFromPreselection(option: SelectedOption) {
    this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    const doc = this.options.find((t) => t.value === option.value);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: Option) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    } else if (!this._isSelected(option.value)) {
      this.selected.push({ value: option.value, label: option.label, selected: true, display: false });
    }
  }

  formatSelection() {
    if (!this.multiple) {
      const value = this.options.find(o => o.selected);
      return value ? this.translate.instant(value.label) : undefined;
    } else {
      const value = this.options.filter(o => o.selected);
      if (value.length > 0) {
        return value.length === 1 ? this.translate.instant(value[0].label) : `${this.label} (${value.length})`;
      } else {
        return undefined;
      }
    }
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.options.forEach(o => {
        if (o.value !== option.value) {
          o.selected = false;
        }
      })
    }
    this.onChange();
  }

  async onChange() {
    if (!this.multiple) {
      const value = this.options.find(o => o.selected);
      this.selection = value ? this.translate.instant(value.label) : undefined;
      this.valueChange.emit(value?.value);
      this.change.emit({ value });
      this.trigger?.close();
    } else {

      const values = _.unionWith([...this.options.filter(c => c.selected).map(c => c.value), ...this.selected.map((o) => o.value)]);
      if (values.length > 0) {
        if (values.length === 1) {
          const matching = this.options.find((o) => o.value === values[0]);
          if (matching)
            this.selection = this.translate.instant(matching.label)
        } else {
          this.selection = `${this.label} (${values.length})`;
        }

      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values.map(v => v.value));
      this.change.emit({ value: values });

    }


  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return (this._filter && this._filter.length > 0) ? this.options.filter(option => {
      return Object.values(option).filter(value => typeof value === 'string').some(value => value.toLowerCase().includes(this._filter?.toLowerCase()))
    }) : this.options;
  }

  onSearch(event: any) {
    if (event && event.hasOwnProperty('value')) {
      this._filter = event.value;
    }
  }

  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.options.forEach(option => {
      option.selected = false
    })
    this.selected = [];
    this.selection = undefined;
    this._filter = undefined;
    this.trigger?.close();
    this.onChange();
  }
}
