import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trademark, TrademarkSearch, TrademarkStatus } from '../models/trademark';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paginator } from '../models/paginator';


export interface ExternalCreateTrademarkResult {
  ok: boolean,
  error?: string,
  trademark: Trademark
}


@Injectable({
  providedIn: 'root'
})
export class TrademarkService {


  private trademarks: Trademark[] = [];

  constructor(
    private http: HttpClient
  ) {

  }

  countAll(countriesOfDesignation?: string[], classNumbers?: number[], query?: string): Promise<{ count: number }> {
    let params = new HttpParams();

    if (countriesOfDesignation) {
      countriesOfDesignation.forEach(country => {
        params = params.append('countryOfDesignation[]', country.toUpperCase());
      })
    }

    if (classNumbers) {
      classNumbers.forEach(classNumber => {
        params = params.append('classNumber[]', classNumber);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<{ count: number }>(`${environment.backend}/trademarks/count`, { params }));
  }

  graphAll() {
    return firstValueFrom(this.http
      .get<{ obligated: number, notObligated: number }>(`${environment.backend}/trademarks/graph`));
  }


  retrieveAll(pageNumber: number, limit: number, offset: number, sort?: string, countriesOfDesignation?: string[], classNumbers?: number[], query?: string): Promise<Paginator<Trademark>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    if (countriesOfDesignation) {
      countriesOfDesignation.forEach(country => {
        params = params.append('countryOfDesignation[]', country.toUpperCase());
      })
    }

    if (classNumbers) {
      classNumbers.forEach(classNumber => {
        params = params.append('classNumber[]', classNumber);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Trademark>>(`${environment.backend}/trademarks/search`, { params }));
  }

  create(trademark: Trademark): Promise<Trademark> {
    return firstValueFrom(this.http
      .post<Trademark>(`${environment.backend}/trademarks`, trademark));
  }

  createExternal(trademarks: TrademarkSearch[]): Promise<{ declared: ExternalCreateTrademarkResult[], errors: ExternalCreateTrademarkResult[] }> {
    return firstValueFrom(this.http
      .post<{ declared: ExternalCreateTrademarkResult[], errors: ExternalCreateTrademarkResult[] }>(`${environment.backend}/trademarks/external`, trademarks));
  }

  retrieve(id: string): Promise<Trademark> {
    return firstValueFrom(this.http
      .get<Trademark>(`${environment.backend}/trademarks/${id}`));
  }

  retrieveExternal(id: string): Promise<Trademark> {
    return firstValueFrom(this.http
      .get<Trademark>(`${environment.backend}/trademarks/${id}/external`));
  }

  update(trademark: Trademark): Promise<Trademark> {
    delete trademark.organizationId;
    let body: any = { ...trademark };
    Object.keys(body).forEach(key => {
      if (!body[key]) body[key] = null;
    });
    return firstValueFrom(this.http
      .put<Trademark>(`${environment.backend}/trademarks/${trademark._id}`, body));
  }

  delete(trademark: Trademark): Promise<any> {
    return firstValueFrom(this.http
      .delete<Trademark>(`${environment.backend}/trademarks/${trademark._id}`));
  }

  searchOne(identifierNumber: string, countriesOfDesignation: string[]) {
    let params = new HttpParams()
      .set('identifierNumber', identifierNumber)

    if (countriesOfDesignation) {
      countriesOfDesignation.forEach(country => {
        params = params.append('countryOfDesignation[]', country);
      })
    }
    return firstValueFrom(this.http
      .get<Trademark>(`${environment.backend}/trademarks/search/external`, { params }));
  }

  search(trademarks: { identifierNumber: string, countryOfDesignation: string[] }[]): Promise<TrademarkSearch[]> {
    return firstValueFrom(this.http
      .post<TrademarkSearch[]>(`${environment.backend}/trademarks/search/external`, { trademarks }));
  }

  uploadReproduction(reproduction: File): Promise<{ url: string }> {
    const formData = new FormData();
    formData.append("image", reproduction);
    return firstValueFrom(this.http.post<{ url: string }>(`${environment.backend}/upload/trademarkReproduction`, formData));
  }

  getReproductionAsBlob(uri: string) {
    return firstValueFrom(this.http.get(uri, { responseType: 'blob' }));
  }

}
