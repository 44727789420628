<mat-card>
  <div class="fx-column">
    <div class="fx-row align-items-center justify-content-between">
      <span class="text light">
        {{label | translate}}
      </span>
      <a [routerLink]="link.href" class="small" *ngIf="link">
        {{link.label | translate}}
      </a>
    </div>
    <app-animated-number [value]="value" [class]="'huge main'"></app-animated-number>
  </div>
</mat-card>