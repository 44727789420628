<table mat-table [dataSource]="filteredDocuments" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary"
        [checked]="hasPageSelection() && isAllSelected()" [indeterminate]="hasPageSelection() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(row) : null"
        [checked]="isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="upper small light bold">
        {{'DOCUMENT.NAME' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-row align-items-center justify-content-start">
        <img [src]="documentIcon(document)" [height]="35" class="mr-10" />
        <div class="fx-column name-container">
          <span class="text bold name">{{document?.name || '-'}}</span>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="class">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="upper small light bold">
        {{'DOCUMENT.CLASS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <span class="text">{{document.class ? ('DOCUMENT_CLASSES.'+document.class.toUpperCase() | translate) :
        '-'}}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <span class="upper small light bold">
        {{'DOCUMENT.STATUS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-column">
        <div class="fx-row align-items-center justify-content-start" *ngFor="let status of document.status">
          <mat-icon class="mr-5" [class.green]="status === 'OK'" [class.red]="status === 'ERROR'"
            [class.orange]="status && !['OK','ERROR'].includes(status)" [class.light]="!status"
            fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="status ? ('BATCH.DOCUMENT_'+status+'_HINT' | translate) :
          ('BATCH.DOCUMENT_PENDING_HINT' | translate)">info</mat-icon>
          <span class="text">{{status ? ('BATCH.DOCUMENT_'+status | translate) :
            ('BATCH.DOCUMENT_PENDING' | translate)}}</span>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let document">
      <button mat-icon-button>
        <mat-icon class="main" fontSet="material-icons-outlined">navigate_next</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="clickable" (click)="toDocument(row)">
  </tr>
</table>
<mat-paginator [length]="documents.length || 0" [pageSize]="batchService.pager.limit || 0"
  [pageIndex]="batchService.pager.page || 0" [pageSizeOptions]="[10, 25, 50, 100]" (page)="handlePage($event)">
</mat-paginator>